// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-covid-covid-tsx": () => import("./../../../src/templates/covid/covid.tsx" /* webpackChunkName: "component---src-templates-covid-covid-tsx" */),
  "component---src-templates-health-health-tsx": () => import("./../../../src/templates/health/health.tsx" /* webpackChunkName: "component---src-templates-health-health-tsx" */),
  "component---src-templates-index-page-index-tsx": () => import("./../../../src/templates/index-page/index.tsx" /* webpackChunkName: "component---src-templates-index-page-index-tsx" */),
  "component---src-templates-mozambique-mozambique-tsx": () => import("./../../../src/templates/mozambique/mozambique.tsx" /* webpackChunkName: "component---src-templates-mozambique-mozambique-tsx" */)
}

