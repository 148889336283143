export const globalColors = [
    'white',
    'yellow',
    'grey100',
    'grey200',
    'grey300',
    'blue100',
    'blue200',
    'blue300',
    'blue400',
    'green100',
    'green200',
    'green300',
    'green400',
    'red100',
    'red200',
    'red300',
    'red400',
    'darkorange100',
    'darkorange200',
    'darkorange300',
    'darkorange400',
    'teal',
    'teal100',
    'teal200',
    'teal300',
    'teal400',
    'gold100',
    'gold200',
    'gold300',
    'gold400',
] as const;

export type TGlobalColors = typeof globalColors[number];
