import { createGlobalStyle } from 'styled-components';

import { TThemeProperties } from '../../styles';
import 'mapbox-gl/dist/mapbox-gl.css';

export const GlobalStyle = createGlobalStyle<TThemeProperties>`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    html,
    body {
        width: 100%;
        height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        font-size: 1.6rem;
        font-family: ${properties => properties.theme.typography.fontFamily};
        line-height: 1.5;
    }

    abbr {
        text-decoration: none;
    }

`;
