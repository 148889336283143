import { createMuiTheme } from '@material-ui/core/styles';

export const defaultTheme = createMuiTheme({
    typography: {
        fontFamily:
            'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
    },
    palette: {
        primary: {
            main: '#0b4f67',
        },
        secondary: {
            main: '#000',
        },
        colors: {
            white: '#f8f8f8',
            yellow: '#ffdb58',
            grey100: '#dcdcdc',
            grey200: '#2f4f4f',
            grey300: '#dcdcdc',
            blue100: '#eff3ff',
            blue200: '#bdd7e7',
            blue300: '#6baed6',
            blue400: '#2171b5',
            green100: '#edf8e9',
            green200: '#bae4b3',
            green300: '#74c476',
            green400: '#238b45',
            red100: '#fee5d9',
            red200: '#fcae91',
            red300: '#fb6a4a',
            red400: '#cb181d',
            darkorange100: '#FED1B1',
            darkorange200: '#FDAF76',
            darkorange300: '#fd8d3c',
            darkorange400: '#E37E36',
            teal: '#0b4f67',
            teal100: '#B2D6D4',
            teal200: '#66ADA9',
            teal300: '#007770',
            teal400: '#00534E',
            gold100: '#FCE3AD',
            gold200: '#FBD685',
            gold300: '#f9bb34',
            gold400: '#E0A82E',
        },
    },
});
